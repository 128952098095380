<template>
  <section>
    <Portal to="headerActions">
      <Link Link to="/data/employees/add" isRouter :permission="Perm.create">
        <b-button
          type="is-primary"
          :label="$t('button.add', [$tc('employee.label').toLowerCase()])"
          icon-left="plus"
        />
      </Link>
    </Portal>
    <b-loading :active="loading" />
    <div class="employees flex">
      <Base-searcher
        class="mr-4"
        api="employees"
        @updated="onSearcherUpdate"
        queryKey="full_name"
        :placeholder="$t('employee.search.ph')"
        :filterOptions="{
          label: $t('employee.type'),
          api: 'user_types?per_page=9999',
          data: [],
          field: 'name',
          model: 'user_type_id'
        }"
      >
        <template #default="{ data }">
          <Searcher-card
            v-for="item in data"
            :key="item.id"
            :active="employee == item"
            :employee="item"
            @click="onSelect"
          />
        </template>
      </Base-searcher>
      <div class="rigth is-full-touch">
        <Card :employee="employee" />
      </div>
    </div>
  </section>
</template>
<script>
import SearcherCard from '@/components/Employees/SearcherCard.vue';
import Card from '@/components/Employees/Card';
import BaseSearcher from '@/components/BaseSearcher/Searcher.vue';
import { Permissions } from '@/utils/Secure';
import { Link } from '@/components';
export default {
  components: {
    Card,
    BaseSearcher,
    Link,
    SearcherCard
  },
  data() {
    return {
      employee: {},
      loading: false
    };
  },
  computed: {
    Perm() {
      return Permissions.Employees;
    }
  },
  methods: {
    onSearcherUpdate({ page, items }) {
      if (page == 1) this.employee = items[0] || {};
    },
    onSelect(employee) {
      this.employee = employee;
    }
  }
};
</script>
<style lang="sass" scoped>
.employees
  .searcher
    min-width: 400px
    width: 35%
    max-width: 650px
  .employee
    height: calc(100% - 75px)
  ::v-deep .infinite-scroll
    height: calc(100% - 80px)
  .rigth
    display: flex
    flex-flow: column
    width: 65%
    justify-content: space-between
    padding-bottom: 7px
</style>
