<template>
  <Card class="p-card-il searchCard" :class="active ? 'active' : ''" @click="onCardClick">
    <div class="picture-container" :class="disabled">
      <div class="picture">
        <Picture
          :src.sync="employee.pictureSrc"
          :preasigned="{
            apiUrl: '/presigned/employees',
            fileName: `${employee.id}.png`
          }"
          :alt="employee.full_name"
        />
      </div>
    </div>
    <div class="details" :class="disabled">
      <div class="flex header">
        <strong>{{ employee.full_name }}</strong>
        <strong>{{ employee.social_security_number }}</strong>
      </div>
      <div v-if="employee.user_type_name" class="role-tag">
        <div>
          <p>{{ employee.user_type_name }}</p>
        </div>
      </div>
      <div class="tag" v-if="employee.role_name">{{ employee.role_name }}</div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card.vue';
import Picture from '@/components/Picture.vue';

export default {
  components: {
    Card,
    Picture
  },
  computed: {
    disabled() {
      return this.employee.is_active ? '' : 'is-disabled';
    }
  },
  methods: {
    onCardClick() {
      this.$emit('click', this.employee);
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    employee: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="sass" scoped>
$pictureSize: 72px
$tagColor: #4E2A9B
$tagBackground: #C5B5E7
.p-card-il
  ::v-deep
    .card-content
      padding: 0
      display: flex
.picture-container
  justify-content: center
  min-width: $pictureSize
  width: $pictureSize
  display: flex
  flex-flow: column
  margin-right: 25px
  .rate
    font-size: 12px
    margin: 0 auto
  .picture
    height: $pictureSize
    border-radius: $br-md
    overflow: hidden
    .b-image-wrapper
      margin: 0
.details
  width: 100%
  display: flex
  flex-flow: column
  .role-tag
    margin-top: 0.5rem
    display: flex
    div
      background: $light-blue-100
      flex-flow: initial
      background-size: contain
      border-radius: 10px
      p
        color: $light-blue-800
        font-size: 14px
        margin: 0.2rem 0.5rem
        font-weight:500
        line-height: 140%
  .header
    justify-content: space-between
    strong
      font-size: $f-lg
  p
    color: $primary
    margin-bottom: 5px
.tag
  color: $tagColor
  background: $tagBackground
  text-transform: uppercase
  font-weight: 700
  width: fit-content
  border-radius: $br-md
</style>
