<template>
  <Modal
    id="password-manager"
    :isOpen.sync="isModalOpen"
    :header="$t('password_manager.New_password_generated')"
    @close="resetForm"
    sufixText="New password:"
    isCloseOutlined
  >
    <div class="new-password-container flex">
      <p>
        <strong>{{ $t('password.newLabel') }}:</strong> {{ password }}
      </p>
      <Link icon="content-copy" @click="copyToClipboard" />
    </div>
  </Modal>
</template>
<script>
import { Modal, Link } from '@/components';
export default {
  components: {
    Modal,
    Link
  },
  data() {
    return {
      isModalOpen: this.isOpen,
      password: this.newPassword,
      loading: false
    };
  },
  methods: {
    resetForm() {
      this.password = '';
      this.isModalOpen = false;
    },
    copyToClipboard() {
      let copy = this.password;
      const el = document.createElement('textarea');
      el.value = copy;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$buefy.notification.open({
        message: this.$t('global.copiedToClipboard'),
        type: 'is-success'
      });
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      this.$emit('update:isOpen', value);
      if (!value) this.resetForm();
    },
    newPassword() {
      this.password = this.newPassword;
    }
  },
  props: {
    isOpen: { type: Boolean, default: false },
    newPassword: { type: String, default: null }
  }
};
</script>
<style lang="sass" scoped>
#password-manager
  ::v-deep
    .modal-content
      max-width: 425px !important
    .card
      .new-password-container
        border: 1px solid $gray-200
        filter: drop-shadow(0px 10px 20px rgba(118, 133, 249, 0.15))
        border-radius: 5px
        padding: 10px 20px
        justify-content: space-between
        p
          font-size: 16px
          strong
            font-weight: 700
        .icon
          &:hover
            cursor: pointer
      .modal-footer .flex
        justify-content: flex-end
        .button
          min-width: 40px
</style>
<style lang="sass">
.dark
  #password-manager
      .card
        .new-password-container
          border: 1px solid $gray-200
          p
            color: $gray-200
            strong
              color: $gray-200
</style>
