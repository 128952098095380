var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('Portal',{attrs:{"to":"headerActions"}},[_c('Link',{attrs:{"Link":"","to":"/data/employees/add","isRouter":"","permission":_vm.Perm.create}},[_c('b-button',{attrs:{"type":"is-primary","label":_vm.$t('button.add', [_vm.$tc('employee.label').toLowerCase()]),"icon-left":"plus"}})],1)],1),_c('b-loading',{attrs:{"active":_vm.loading}}),_c('div',{staticClass:"employees flex"},[_c('Base-searcher',{staticClass:"mr-4",attrs:{"api":"employees","queryKey":"full_name","placeholder":_vm.$t('employee.search.ph'),"filterOptions":{
        label: _vm.$t('employee.type'),
        api: 'user_types?per_page=9999',
        data: [],
        field: 'name',
        model: 'user_type_id'
      }},on:{"updated":_vm.onSearcherUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var data = ref.data;
return _vm._l((data),function(item){return _c('Searcher-card',{key:item.id,attrs:{"active":_vm.employee == item,"employee":item},on:{"click":_vm.onSelect}})})}}])}),_c('div',{staticClass:"rigth is-full-touch"},[_c('Card',{attrs:{"employee":_vm.employee}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }