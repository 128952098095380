<template>
  <Card :class="employee.id ? '' : 'is-disabled'">
    <PasswordManagerModals :isOpen.sync="showPasswordManagerModal" :newPassword="newPassword" />
    <template #header>
      <div class="flex f-jsb f-ai">
        <p>
          {{ employee.full_name || $tc('employee.label', 1) }}
        </p>
        <PhoneViewer :phone="employee.mobile_phone_number" justIcon />
      </div>
    </template>
    <div>
      <div class="profile flex">
        <div id="prof-pic" class="flex">
          <MainCardPicture
            :id="employee.id"
            :src="employee.pictureSrc"
            api="employees"
            :alt="employee.full_name"
          />
          <div class="tag" v-if="employee.role_name">{{ employee.role_name }}</div>
        </div>

        <div class="details">
          <h2>{{ $t('employee.infoLabel') }}</h2>
          <div class="flex f-jsb mb-4">
            <Card-item :title="$t('employee.homeAdrs')">
              <AddressViewer
                :address="address"
                :mapBreadcumbs="['employee.label', employee.full_name]"
              />
            </Card-item>
          </div>
          <div class="flex f-jsb mb-5">
            <Card-item :title="$t('contact.mobile')">
              <PhoneViewer :phone="employee.mobile_phone_number" />
            </Card-item>
            <Card-item :title="$t('contact.email')" :value="employee.email" :upper="false" />
          </div>
          <div class="flex f-jsb mb-5">
            <Card-item :title="$t('global.userName')" :value="username" />
            <Card-item :title="$t('employee.companyRole')" :value="employee.user_type_name" />
            <Card-item
              :title="$t('employee.licenseNumber')"
              :value="employee.driver_license_number"
            />
          </div>
          <div class="flex f-jsb mb-5">
            <Card-item
              :class="licenseExpirated"
              :title="$t('employee.licenseExp')"
              :value="employee.driver_license_expiration_date"
            />
          </div>
        </div>
      </div>
    </div>
    <PastServices class="past-services" />
    <template #footer>
      <div class="flex f-full f-ai f-jsb">
        <My-switch
          :value="employee.is_active"
          :text="$tc('switch.active', employee.is_active ? 1 : 2)"
          @input="toggleActive"
          :disabled="!employee.id || loading.toggleActive"
          :loading="loading.toggleActive"
          :permission="Permission.setDefault"
        />
        <div class="buttons">
          <Button
            type="is-primary"
            @click="handleConfirmGenerateNewPassword"
            :label="$t('button.PasswordManager')"
            :disabled="!employee.id || employee.id === userID"
            :loading="loading.generatePassword"
            :permission="PermissionResetPassword"
            outlined
          />
          <Button
            type="is-primary"
            @click="onEdit"
            :label="$t('button.edit')"
            :disabled="!employee.id"
            :permission="Permission.update"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import AddressViewer from '@/components/Address/AddressViewer.vue';
import PastServices from './PastServices.vue';
import { Permissions } from '@/utils/Secure';
import { PasswordManagerModals } from '@/views/Employees/fragments';
import { Card, CardItem, iSwitch, PhoneViewer, MainCardPicture, Button } from '@/components';
import moment from 'moment';
import { confirm } from '../../utils/dialog';

export default {
  components: {
    AddressViewer,
    Button,
    Card,
    CardItem,
    MainCardPicture,
    MySwitch: iSwitch,
    PastServices,
    PhoneViewer,
    PasswordManagerModals
  },
  data() {
    return {
      data: {},
      showPasswordManagerModal: false,
      loading: { toggleActive: false, generatePassword: false },
      isDisabled: !this.employee.is_active,
      username: '',
      expDate: '',
      licenseExpirated: '',
      newPassword: null
    };
  },
  beforeUpdate() {
    this.licenseExpirated = this.isLicenseExpirated();
    return this.licenseExpirated;
  },
  computed: {
    address() {
      const { full_address, latitude: lat, longitude: lng } = this.employee;
      return { full_address, latLng: { lat, lng } };
    },
    Permission() {
      return Permissions.Employees;
    },
    PermissionResetPassword() {
      return Permissions.PasswordManager.reset;
    },
    userID() {
      const { employee_id } = this.$store.getters.AUTH || {};
      return employee_id;
    }
  },
  watch: {
    employee() {
      this.isDisabled = !this.employee.is_active;
      this.username = this.employee.email ? this.employee.email.split('@')[0] : '';
      this.expDate = this.employee.driver_license_expiration_date
        ? this.$moment(this.employee.driver_license_expiration_date).format('DD/MM/YYYY')
        : '';
    },
    showPasswordManagerModal(value) {
      if (!value) {
        this.newPassword = null;
      }
    }
  },
  methods: {
    onEdit() {
      this.$router.push({ path: `/data/employees/${this.employee.id}` });
    },
    async toggleActive(is_active) {
      this.loading.toggleActive = true;
      try {
        await this.Api.patch(`employees/${this.employee.id}`, { is_active });
        this.employee.is_active = is_active;
        this.isDisabled = !is_active;
      } catch (error) {
        const value = this.employee.is_active;
        this.employee.is_active = !value;
        setTimeout(() => {
          this.employee.is_active = value;
        }, 100);
      }
      this.loading.toggleActive = false;
    },
    isLicenseExpirated() {
      const today = moment().format('YYYY-MM-DD');
      const dateDifference = moment(this.employee.driver_license_expiration_date).diff(
        moment(today),
        'months'
      );
      if (dateDifference >= 2) return '';
      if (this.employee.driver_license_expiration_date >= today) return 'license-expiration';
      return 'license-expiration-exceeded';
    },
    handleConfirmGenerateNewPassword() {
      confirm({
        title: this.$t('confirms.continue'),
        message: this.$t('confirms.generatePassword'),
        cancelText: this.$t('confirm.no'),
        confirmText: this.$t('confirm.yes'),
        onConfirm: () => {
          this.onGeneratePassword();
        }
      });
    },
    async onGeneratePassword() {
      try {
        this.loading.generatePassword = true;
        const { data } = await this.Api.patch(`/users/${this.employee.user_id}/fetch_password`);
        this.newPassword = data.password;
        this.showPasswordManagerModal = true;
        this.loading.generatePassword = false;
      } catch (error) {
        console.log(error);
      }
    }
  },
  props: {
    employee: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="sass" scoped>
$tagColor: #4E2A9B
$tagBackground: #C5B5E7
.card
  height: 100%
  width: 100%
  margin: 0
  display: flex
  flex-flow: column
  ::v-deep .card-content
    display: flex
    flex-flow: column
    justify-content: space-between
    flex: 1
    margin-top: 1.5rem
    .license-expiration .ci-content
      color: $orange-650
    .license-expiration-exceeded .ci-content
      color: $red-700
.profile
  .tag
    color: $tagColor
    background: $tagBackground
    text-transform: uppercase
    font-weight: 700
    width: fit-content
    border-radius: $br-md
    margin-top: 15px
  .details
    width: 100%
    @media screen and ( max-width: $bp-xxl )
      max-width: 100%

    h2
      font-size: 18px
      font-weight: bold
      margin-bottom: 15px
    .flex
      margin-bottom: 10px
.past-services
  ::v-deep
    .pastServiceCard
      width: calc(33% - 10px)
.buttons
  justify-content: space-between
.iSwitch
  margin: 0
</style>
