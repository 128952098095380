<template>
  <div>
    <PastServices>
      <PastServiceCard
        title="Incidents"
        theme="danger"
        icon="close-circle"
        text="2 incidents"
        subText="this month"
      />
      <PastServiceCard
        title="Trips"
        theme="success"
        icon="star"
        text="1256"
        subText="Trips served"
      />
      <PastServiceCard
        title="Changes History"
        icon="package-down"
        :modalOptions="{ title: 'Modal title' }"
        text="15"
        :loading="loading"
      >
        <template #modal-body> MODAL CONTENT </template>
      </PastServiceCard>
    </PastServices>
  </div>
</template>

<script>
import { PastServices, PastServiceCard } from '@/components';
export default {
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  data() {
    return {
      loading: true
    };
  },
  components: {
    PastServices,
    PastServiceCard
  }
};
</script>

<style lang="sass" scoped>
.pastServices
  margin: 0!important
  padding: 0!important
  ::v-deep
    .card-content
      padding: 15px 0 0!important
      margin: 0 -5px!important
</style>
